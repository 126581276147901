<template>
  <div class="about">
    <div class="banner">
      
    </div>
    <div class="zxjj">
      <div class="zxjj_left">
        <div class="zxjj_left_title">
          <b>{{ titleMessage.name }}</b>
        </div>
        <div
          v-for="(item, index) in titleMessage.childItem"
          :key="index"
          :class="index == activeIndex ? 'zxjj_left_listacitve' : 'zxjj_left_list'"
          @click="titleActive(item, index)"
        >
          <a>{{ item.name }}</a>
        </div>
      </div>
      <div class="zxjj_right">
        <div class="zxjj_right_top">
          <div>
            <a
              style="
                display: block;
                height: 67px;
                line-height: 67px;
                font-weight: normal;
                color: #003068;
                font-size: 18px;">
              {{ acitveItem }}
            </a>
          </div>
          <div>
            <!-- <el-breadcrumb separator-class="el-icon-arrow-right">
              <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
              <el-breadcrumb-item>{{ acitveItem }}</el-breadcrumb-item>
            </el-breadcrumb> -->
          </div>
        </div>
        <div class="zxjj_right_content">
           <div class="l_list_wrap" v-if="activeIndex == 0">
            <div class="l_list"  @click="todetail">
              <div style="width: 250px; height: 150px; overflow: hidden">
                <img
                  class="l_list_img"
                  src="../../../public/image/img.jpg"
                  alt=""
                />
              </div>
              <div
                style="width: 540px; padding: 0px 40px 0px 40px; color: #666666"
              >
                <a class="news_tit"
                  >更新中…</a
                >
                <div class="news_zi">
                  <!-- 为弘扬企业社会责任，促进医疗卫生事业发展。北京新兴卫生产业发展基金会为青年医生的临床医疗研究项 -->
                </div>
                <!-- <i class="news_time">2020-06-08</i> -->
              </div>
            </div>
            <!-- <div class="l_list" @click="todetail">
              <div style="width: 250px; height: 150px; overflow: hidden">
                <img
                  class="l_list_img"
                  src="../../../public/image/img.jpg"
                  alt=""
                />
              </div>
              <div
                style="width: 540px; padding: 0px 40px 0px 40px; color: #666666"
              >
                <a class="news_tit"
                  >【喜讯】基金会获北京市非营利组织免税资格</a
                >
                <div class="news_zi">
                  北京市财政局 国家税务总局北京市税务局关于公布北京市2016年度第十七批、2017年度第十四批、2018年度第十三批、2019年度第八批和2020年度第二批取得非营利组织免税资格单位名单的通知
                  北京市财政局 国家税务总局北京市税务局关于公布北京市2016年度第十七批、2017年度第十四批、2018年度第十三批、2019年度第八批和2020年度第二批取得非营利组织免税资格单位名单的通知
                </div>
                <i class="news_time">2020-06-08</i>
              </div>
            </div> -->
        </div>
          <ul v-else-if="activeIndex == 1" class="nlist_ul">
            <li class="clear" @click="todetail2">
              <a class="el"
                ><i class="circle left"></i
                ><dev> 更新中… </dev>
              </a>
              <!-- <span class="time right">2020-06-03</span> -->
            </li>
          </ul>
          <ul class="list_peo" v-else-if="activeIndex == 2">
            <li>
              <a>
                <div class="pic">
                  <span class="pic-bg"></span>
                  <img
                    src="../../../public/image/bg_img.jpg"
                    style="width: 150px; height: 200px"
                    alt=""
                  />
                </div>
              </a>
              <div class="info">
                <a href="">
                  <h3>陈伟<span>教授</span></h3>
                </a>
                <p class="over">
                  <strong>个人主页</strong>&nbsp;:&nbsp;<span
                    >膜蛋白动态识别及跨膜激活,工程, 系统生物医学工程</span
                  >
                </p>
                <p class="over">
                  <strong>所在学科系</strong>&nbsp;:&nbsp;<span
                    >细胞生物学系</span
                  >
                </p>
                <p class="over">
                  <strong>所在研究中心</strong>&nbsp;:&nbsp;<span
                    >系统医学研究中心</span
                  >
                </p>
                <p title="">
                  <strong>研究方向</strong>&nbsp;:&nbsp;<span
                    >膜蛋白动态识别及跨膜激活,单细胞及单分子生物医学工程,
                    系统生物医学工程</span
                  >
                </p>
              </div>
            </li>
            <li>
              <a>
                <div class="pic">
                  <span class="pic-bg"></span>
                  <img
                    src="../../../public/image/bg_img.jpg"
                    style="width: 150px; height: 200px"
                    alt=""
                  />
                </div>
              </a>
              <div class="info">
                <a href="">
                  <h3>陈伟<span>教授</span></h3>
                </a>
                <p class="over">
                  <strong>个人主页</strong>&nbsp;:&nbsp;<span
                    >膜蛋白动态识别及跨膜激活,工程, 系统生物医学工程</span
                  >
                </p>
                <p class="over">
                  <strong>所在学科系</strong>&nbsp;:&nbsp;<span
                    >细胞生物学系</span
                  >
                </p>
                <p class="over">
                  <strong>所在研究中心</strong>&nbsp;:&nbsp;<span
                    >系统医学研究中心</span
                  >
                </p>
                <p title="">
                  <strong>研究方向</strong>&nbsp;:&nbsp;<span
                    >膜蛋白动态识别及跨膜激活,单细胞及单分子生物医学工程,
                    系统生物医学工程</span
                  >
                </p>
              </div>
            </li>
            <li>
              <a>
                <div class="pic">
                  <span class="pic-bg"></span>
                  <img
                    src="../../../public/image/bg_img.jpg"
                    style="width: 150px; height: 200px"
                    alt=""
                  />
                </div>
              </a>
              <div class="info">
                <a href="">
                  <h3>陈伟<span>教授</span></h3>
                </a>
                <p class="over">
                  <strong>个人主页</strong>&nbsp;:&nbsp;<span
                    >膜蛋白动态识别及跨膜激活,工程, 系统生物医学工程</span
                  >
                </p>
                <p class="over">
                  <strong>所在学科系</strong>&nbsp;:&nbsp;<span
                    >细胞生物学系</span
                  >
                </p>
                <p class="over">
                  <strong>所在研究中心</strong>&nbsp;:&nbsp;<span
                    >系统医学研究中心</span
                  >
                </p>
                <p title="">
                  <strong>研究方向</strong>&nbsp;:&nbsp;<span
                    >膜蛋白动态识别及跨膜激活,单细胞及单分子生物医学工程,
                    系统生物医学工程</span
                  >
                </p>
              </div>
            </li>
          </ul>
           <div v-else-if="activeIndex == 99">
            <b style="display:block;width:100%;text-align:center;font-size:18px;line-height:100px">关于公益性捐赠税前扣除资格确认有关衔接事项的公告</b>
            <small style="display:block;width:100%;text-align:center;border-bottom:1px solid gray;line-height:50px">时间：2020-07-30</small>
            <div style="width:100%;margin-top:50px;font-family: 仿宋;line-height: 150%;font-size: 21px;">    
                  为鼓励社会公益性捐赠，做好《财政部 税务总局 民政部关于公益性捐赠税前扣除有关事项的公告》（财政部 税务总局 民政部公告2020年第27号）与相关文件的衔接工作，并考虑新冠肺炎疫情影响，现就有关事项公告如下： 一、确认2020年度——2022年度公益性捐赠税前扣除资格时，部分条件可按照以下规定执行：
                （一）在民政部门依法登记的慈善组织和其他社会组织（以下统称社会组织）2018年和2019年的公益慈善事业支出和管理费用比例，可按照《民政部 财政部 国家税务总局关于印发《关于慈善组织开展慈善活动年度支出和管理费用的规定》的通知》（民发〔2016〕189号）有关规定执行。（二）社会组织2018年至本公告发布之日最近一期的评估等级达到3A以上（含3A）。对于2019年成立的社会组织，以及2019年至本公告发布之日已接受评估但尚未出具结论的社会组织，确认资格时可暂不考虑其评估等级。
                （三）确认公益性捐赠税前扣除资格时，可暂不考虑社会组织的非营利组织免税资格。（四）按照本条取得公益性捐赠税前扣除资格的，在资格有效期内，应取得3A以上（含3A）评估等级，且取得非营利组织免税资格。二、确认2021年度——2023年度公益性捐赠税前扣除资格时，社会组织2019年和2020年的公益慈善事业支出和管理费用比例，可按照《民政部 财政部 国家税务总局关于印发《关于慈善组织开展慈善活动年度支出和管理费用的规定》的通知》（民发〔2016〕189号）有关规定执行。
                三、本公告自2020年1月1日起执行。特此公告。
            </div>
          </div>
            <div v-else-if="activeIndex == 98">
            <b style="display:block;width:100%;text-align:center;font-size:18px;line-height:100px">一叶红船映初心（壹）</b>
            <small style="display:block;width:100%;text-align:center;border-bottom:1px solid gray;line-height:50px">时间：2020-07-30</small>
            <div style="width:100%;margin-top:50px;font-family: 仿宋;line-height: 150%;font-size: 21px;">    
              北京新兴卫生产业发展基金会百年光辉历程学党史系列活动一叶红船映初心（壹）2021年是中国共产党成立100周年。在这个伟大的历史时刻，回顾中国共产党团结带领中国人民不懈奋斗的光辉历程，凝聚起启航新征程的磅礴伟力。百年苦难辉煌，百年沧海桑田。1921年7月，中国共产党诞生了。从南湖的一叶红船起航，到驾驭“中国号”巨轮乘风破浪，这个成立时只有几十人的政党，如今已经成为世界最大规模执政党；这个曾经四分五裂、一穷二白的国家，从低谷走向了复兴，崛起于世界的东方。
              在这建党100周年来临之际，北京医联党委第六流动党员联合党支部开展了“百年光辉历程”学党史系列活动。
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import eventbus from "@/api/eventbus";
export default {
  data() {
    return {
      activeIndex: 0,
      acitveItem:JSON.parse(window.sessionStorage.getItem("titleMessage")).childItem[0].name || "",
      titleMessage:JSON.parse(window.sessionStorage.getItem("titleMessage")) || {},
    };
  },
  mounted() {
    //   eventbus.$on('headerTitle',(message)=>{
    //   console.log('message',message)
    //   window.sessionStorage.setItem('titleMessage',JSON.stringify(message))
    // })
    this.activeIndex = this.$route.query.index;
    eventbus.$on("childindex", (index) => {
      this.activeIndex = index;
      this.acitveItem = this.titleMessage.childItem[index].name;
    });
  },
  methods: {
    titleActive(item, index) {
      this.acitveItem = item.name;
      this.activeIndex = index;
    },
    todetail(){
      // this.xiangqing = true
      // this.activeIndex = 99;
    },
    todetail2(){
      //  this.xiangqing = true
      //  this.activeIndex = 98;
    }
  },
};

</script>
<style scoped>
/*顶部 banner */
.banner {
    width: 100%;
    background-image: url("../../../public/image/banner.jpg");
    background-repeat: no-repeat;
    background-position: center center;
    height: 450px;

    /* animation: 2s opacity2 0s infinite;
    -webkit-animation: 2s opacity2 0s infinite;-moz-animation: 2s opacity2 0s infinite;
    position: relative;
    height: 315px;
    background-image: url("../../../public/image/bg_img.jpg");
    background-repeat: no-repeat;
    background-position: center center;
    transition: background-image 0.5s linear; */
}
.about {
  width: 100%;
  background-color: #f1f1f1;
}
.zxjj {
  width: 100%;
  max-width: 1200px;
  margin: 0px auto;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 20px 0px 100px 0px;
  flex-direction: row;
}
.zxjj_left {
  width: 270px;
      padding-bottom: 36px;
  box-shadow: 0px 2px 10px gray;
  transition: all 0.2s ease-in-out ;
  transform:translateY(0px) ;
  /* transition: width 1.5s, height 1.5s, transform 1.5s;
  -moz-transition: width 1.5s, height 1.5s, -moz-transform 1.5s;
  -webkit-transition: width 1.5s, height 1.5s, -webkit-transform 1.5s;
  -o-transition: width 1.5s, height 1.5s, -o-transform 1.5s; */

}
.zxjj_left:hover {
  cursor: pointer;

}
.zxjj_left_title {
  width: 100%;
  height: 50px;
  background-color: #DEE466;
  text-align: center;
  line-height: 50px;
  color: #000000;
  font-size: 18px;
  border-radius: 0px 35px 35px 0px;
}
.zxjj_left_list {
  width: 100%;
  height: 50px;
  background-color: #ebebeb;
  border-top: 1px solid #ffff;
  margin: 5px 0px;
  border-radius: 0px 25px 25px 0px;
}
.zxjj_left_list:hover {
  background-color: #DEE466;
  color: #ffff;
}
.zxjj_left_list a {
  display: block;
  width: 80%;
  height: 100%;
  float: right;
  line-height: 50px;
}
.zxjj_left_listacitve {
  width: 270px;
  height: 50px;
  margin: 5px 0px;

  background-color: #DEE466;
  color: #fff;
  border-radius: 0px 25px 25px 0px;
  box-shadow: 0px 20px 20px 0px;
  transition: width 1.5s, height 1.5s, transform 1.5s;
  -moz-transition: width 1.5s, height 1.5s, -moz-transform 1.5s;
  -webkit-transition: width 1.5s, height 1.5s, -webkit-transform 1.5s;
  -o-transition: width 1.5s, height 1.5s, -o-transform 1.5s;
}
.zxjj_left_listacitve a {
  display: block;
  width: 80%;
  height: 100%;
  float: right;
  line-height: 50px;
}
.zxjj_right {
  width: 900px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  padding: 0px 0px 0px 20px;
}
.zxjj_right_top {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid #DEE466;
}
.zxjj_right_content {
  width: 100%;
}
.zxjj_right_content p {
  text-indent: 2em;
  line-height: 20px;
  text-align: justify;
  text-justify: inter-ideograph;
  color: #333333;
  line-height: 2em;
}
.list-w .list-list,
.nlist_-ul {
  margin-top: 10px;
  list-style: none;
}
.list-w .list-list li .i,
.nlist_-ul li .circle {
  display: inline-block;
  width: 5px;
  height: 5px;
  margin-top: 22px;
  margin: 22px 8px 0;
  vertical-align: top;
  margin-top: 0;
  vertical-align: middle;
  background: #cecece;
  border-radius: 50%;
}
.list-w .list-list li .time,
.nlist_-ul li .time {
  position: absolute;
  right: 0;
  color: #888888;
}
.list-w .list-list li a,
.nlist_-ul li a {
  font-size: 16px;
  display: block;
  margin-left: 22px;
  margin-right: 90px;
}
.el {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: 46px;
}
.clear {
  position: relative;
  border-bottom: 1px dashed gray;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.clear:after {
  content: " ";
  display: block;
  height: 0;
  line-height: 0;
  visibility: hidden;
  position: absolute;
  top: 10px;
  clear: both;
}
a {
  font-size: 16px;
  color: #333;
  text-decoration: none;
  font-size: 16px;
  display: block;
  margin-left: 22px;
  margin-right: 90px;
}
body,
div,
ul,
li,
h1,
h2,
h3,
h4,
h5,
h6,
input,
i,
a,
img,
p,
button {
  margin: 0;
  padding: 0;
  font-style: normal;
  vertical-align: top;
  border: none;
  outline: none;
  -webkit-tap-highlight-color: transparent;
}
dev:hover {
  cursor: pointer;
  border-bottom: 1px solid #000088;
  color: #000088;
}
.list_peo {
  padding-top: 1px;
}
body,
div,
ul,
li,
h1,
h2,
h3,
h4,
h5,
h6,
input,
i,
a,
img,
p,
button {
  margin: 0;
  padding: 0;
  font-style: normal;
  vertical-align: top;
  border: none;
  outline: none;
  -webkit-tap-highlight-color: transparent;
}
ul {
  display: block;
  list-style-type: disc;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
}
.list_peo li {
  padding: 27px 0 35px 60px;
  overflow: hidden;
  border-bottom: 1px dashed #ddd;
}
li {
  list-style-type: none;
}
.list_peo li a {
  font-size: 16px;
  color: #333;
  text-decoration: none;
}
.list_peo li .pic {
  position: relative;
  float: left;
  width: 150px;
  height: 200px;
  border: 1px solid #ededed;
  -moz-box-shadow: 8px 8px 0px #ededed;
  -webkit-box-shadow: 8px 8px 0px #ededed;
  box-shadow: 8px 8px 0px #ededed;
  z-index: 2;
}
/* .list_peo li .pic-bg {
    width: 150px;
    height: 200px;
    position: absolute;
    left: 9px;
    top: 9px;
    background-color: #ededed;
} */
.list_peo li .info {
  width: 100%;
  box-sizing: border-box;
  padding: 5px 0 0 200px;
}
.list_peo .info p {
  color: #333;
  font-size: 16px;
  line-height: 32px;
}
.el-icon-arrow-right:hover {
  cursor: pointer;
}
.el-breadcrumb-item:hover {
  cursor: pointer;
}
/* 列表内容 */
.l_list_wrap {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
}
.l_list {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 20px 0px 20px 0px;
  border-bottom: 1px solid #eeeeee;
}
.l_list:hover .news_tit {
  color: #d71e06;
  margin-left: 20px;
  cursor: pointer;
}
.news_tit {
  display: block;
  transition: all 0.5s ease 0s;
  overflow: hidden;
  height: 52px;
  line-height: 52px;
  color: #666666;
  font-size: 16px;
  font-weight: bold;
  transition-property: all;
  transition-duration: 1s;
  transition-timing-function: linear;
}
.news_zi {
  height: 50px;
  line-height: 25px;
  font-size: 14px;
  color: #666666;
  overflow: hidden;
  display: -webkit-box;    
  -webkit-box-orient: vertical;    
  -webkit-line-clamp: 2;    
}
.news_time {
  height: 46px;
  line-height: 46px;
  overflow: hidden;
  display: block;
  color: #666666;
  font-style:normal
}

.l_list_img {
  width: 100%;
  height: 100%;
  transition: width 1s, height 1s, transform 1s;
  /* animation: 2s opacity2 0s infinite;
  -webkit-animation: 2s opacity2 0s infinite;-moz-animation: 2s opacity2 0s infinite; */
}
.l_list_img:hover {
  transform: scale(1.2);
}
@media screen and (max-width: 1200px) {
  .zxjj {
    width: 100%;
    max-width: 1200px;
    margin: 0px auto;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 20px 0px 100px 0px;
    flex-direction: column;
  }
  .zxjj_left {
    width: 100%;
    background-color: #ffff;
    transition: width 1.5s, height 1.5s, transform 1.5s;
    -moz-transition: width 1.5s, height 1.5s, -moz-transform 1.5s;
    -webkit-transition: width 1.5s, height 1.5s, -webkit-transform 1.5s;
    -o-transition: width 1.5s, height 1.5s, -o-transform 1.5s;
  }
  .zxjj_left:hover {
    cursor: pointer;
  }
  .zxjj_left_title {
    width: 100%;
    height: 70px;
    background-color: #1f518c;
    text-align: center;
    line-height: 70px;
    color: #ffff;
    font-size: 18px;
    border: 1px solid #DEE466;
    border-radius: 0px 20px 20px 20px;
  }
  .zxjj_left_list {
    width: 100%;
    height: 50px;
    background-color: #ebebeb;
    border-top: 1px solid #ffff;
  }
  .zxjj_left_list:hover {
    background-color: #DEE466;
    color: #ffff;
  }
  .zxjj_left_list a {
    display: block;
    width: 80%;
    height: 100%;
    float: right;
    line-height: 50px;
  }
  .zxjj_left_listacitve {
    width: 100%;
    height: 50px;
    background-color: #DEE466;
    color: #fff;
    transition: width 1s, height 1s, transform 1s;
    -moz-transition: width 1s, height 1s, -moz-transform 1s;
    -webkit-transition: width 1s, height 1s, -webkit-transform 1s;
    -o-transition: width 1s, height 1s, -o-transform 1s;
  }
  .zxjj_left_listacitve a {
    display: block;
    width: 80%;
    height: 100%;
    float: right;
    line-height: 50px;
  }
  .zxjj_right {
    width: 95%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0px 0px 0px 20px;
  }
  .el-icon-arrow-right:hover {
    cursor: pointer;
  }
  .el-breadcrumb-item:hover {
    cursor: pointer;
  }
}
</style>
