<template>
  <div class="about">
    <div class="banner"></div>
    <div class="zxjj">
      <div class="zxjj_left">
        <div class="zxjj_left_title">
          <b>{{ titleMessage.name }}</b>
        </div>
        <div
          v-for="(item, index) in titleMessage.childItem"
          :key="index"
          :class="
            index == activeIndex ? 'zxjj_left_listacitve' : 'zxjj_left_list'
          "
          @click="titleActive(item, index)"
        >
          <a>{{ item.name }}</a>
        </div>
      </div>
      <div class="zxjj_right">
        <div class="zxjj_right_top">
          <div>
            <a
              style="
                display: block;
                height: 67px;
                line-height: 67px;
                font-weight: normal;
                color: #003068;
                font-size: 18px;
              "
            >
              {{ acitveItem }}
            </a>
          </div>
          <div>
            <!-- <el-breadcrumb separator-class="el-icon-arrow-right">
              <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
              <el-breadcrumb-item>{{ acitveItem }}</el-breadcrumb-item>
            </el-breadcrumb> -->
          </div>
        </div>
        <div class="zxjj_right_content">
          <div class="l_list_wrap" v-if="activeIndex == 0">
            <pdf 
                style="width:100%"
                ref="pdf"
                v-for="i in numPages" 
                :key="i" 
                :page="i"
                 src="/北京黉塾公益基金会章程3.23.pdf"
              >
            </pdf>
            <!-- <a target="_blank" href="/北京黉塾公益基金会章程3.23.pdf">北京黉塾公益基金会章程3.23.pdf</a> -->
          </div>

          <ul v-else-if="activeIndex == 1" class="nlist_ul">
            <li class="clear">
              <a class="el"
                ><i class="circle left"></i
                ><dev> 更新中… </dev>
              </a>
              <!-- <span class="time right">2020-06-03</span> -->
            </li>
          </ul>
          <ul class="list_peo" v-else-if="activeIndex == 2">
            <li>
              <a>
                <!-- <div class="pic">
                  <span class="pic-bg"></span>
                  <img
                    src="../../../public/image/bg_img.jpg"
                    style="width: 150px; height: 200px"
                    alt=""
                  />
                </div> -->
              </a>
              <div class="info">
                <a href="">
                  <!-- <h3>陈伟<span>教授</span></h3> -->
                </a>
                <p class="over">
                  <!-- <strong>个人主页</strong> -->
                  &nbsp;&nbsp;<span>更新中…</span
                  >
                </p>
                <!-- <p class="over">
                  <strong>所在学科系</strong>&nbsp;:&nbsp;<span
                    >细胞生物学系</span
                  >
                </p>
                <p class="over">
                  <strong>所在研究中心</strong>&nbsp;:&nbsp;<span
                    >系统医学研究中心</span
                  >
                </p>
                <p title="">
                  <strong>研究方向</strong>&nbsp;:&nbsp;<span
                    >膜蛋白动态识别及跨膜激活,单细胞及单分子生物医学工程,
                    系统生物医学工程</span
                  >
                </p> -->
              </div>
            </li>
          </ul>

          <ul v-else-if="activeIndex == 5" class="nlist_ul">
            <li class="clear" @click="todetail2">
              <a class="el"
                ><i class="circle left"></i
                ><dev> 更新中 </dev>
              </a>
              <!-- <span class="time right">2020-06-03</span> -->
            </li>
          </ul>
          <div v-else-if="activeIndex == 98">
            <b
              style="
                display: block;
                width: 100%;
                text-align: center;
                font-size: 18px;
                line-height: 100px;
              "
              >北京新兴卫生产业发展基金会招募志愿者</b
            >
            <small
              style="
                display: block;
                width: 100%;
                text-align: center;
                border-bottom: 1px solid gray;
                line-height: 50px;
              "
              >时间：2021-03-17</small
            >
            <div style="width: 100%; margin-top: 50px; font-size: 14px; color: #777; text-indent: 2em; line-height: 22px;">
              报名条件：
              1.年满十八周岁且具有完全民事行为能力的在校大学生或应届毕业生；
              2.有责任心、有爱心、有耐心，热爱公益，积极乐观，遇事沉稳理智；
              3.谈吐文明，表达沟通能力较好，无不良嗜好，无严重处分或违法犯罪记录。
              报名时间：长期招募
              报名方式：下载附件《志愿者报名表》并填写，把填写好的《志愿者报名表》PDF版本发送至基金会邮箱（bjwscy@162.com）
              北京新兴卫生产业发展基金会地址：北京市海淀区知春路卫星大厦801室
              北京新兴卫生产业发展基金会联系电话：010—82615893
            </div>
          </div>
          <div v-else-if="activeIndex == 99">
            <b
              style="
                display: block;
                width: 100%;
                text-align: center;
                font-size: 18px;
                line-height: 100px;
              "
              >情满凉山，点亮希望】索玛花爱心助学志愿者招募</b
            >
            <small
              style="
                display: block;
                width: 100%;
                text-align: center;
                border-bottom: 1px solid gray;
                line-height: 50px;
              "
              >时间：2020-07-30</small
            >
            <div style="width: 100%; margin-top: 50px">
              利组织会计制度的规制编制，在所有为弘扬企业社会责任，促进医疗卫生事业发展。北京新兴卫生产业发展基金会为青年医生的临床医疗研究项目，提供资金支持服务。目前共以下两个项目进行中：
              1
              上海复旦大学附属浦东医院，候双兴项目组《血浆净化技术对醒后卒中的个体化治疗研究》。2
              苏州大学附属第一医院，冯军项目组《恶性肿瘤化疗过程中DNA动态监测的临床应用研究》。
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import eventbus from "@/api/eventbus";
import pdf from 'vue-pdf'
export default {
   components:{
      pdf
  },
  data() {
    return {
      activeIndex: 0,
      acitveItem:
        JSON.parse(window.sessionStorage.getItem("titleMessage")).childItem[0]
          .name || "",
      titleMessage:
        JSON.parse(window.sessionStorage.getItem("titleMessage")) || {},
        // url:"../../../public/北京黉塾公益基金会章程3.23.pdf",
        url:"http://image.cache.timepack.cn/nodejs.pdf",
        numPages:8,
    };
  },
  mounted() {
    this.getNumPages()
    //   eventbus.$on('headerTitle',(message)=>{
    //   console.log('message',message)
    //   window.sessionStorage.setItem('titleMessage',JSON.stringify(message))
    // })
    this.activeIndex = this.$route.query.index;
    eventbus.$on("childindex", (index) => {
      this.activeIndex = index;
      this.acitveItem = this.titleMessage.childItem[index].name;
    });
  },
  methods: {
     getNumPages() {
      let loadingTask = pdf.createLoadingTask(this.url)
      loadingTask.promise.then(pdf => {
        this.numPages = pdf.numPages
      }).catch(err => {
        console.error('pdf 加载失败', err);
      })
    },
    titleActive(item, index) {
      this.acitveItem = item.name;
      this.activeIndex = index;
    },
    todetail() {
      this.activeIndex = 99;
    },
    todetail2() {
      this.activeIndex = 98;
    },
  },
};
</script>
<style scoped>
/*顶部 banner */
.banner {
  width: 100%;
  background-image: url("../../../public/image/banner.jpg");
  background-repeat: no-repeat;
  background-position: center center;
  height: 450px;

  /* animation: 2s opacity2 0s infinite;
    -webkit-animation: 2s opacity2 0s infinite;-moz-animation: 2s opacity2 0s infinite;
    position: relative;
    height: 315px;
    background-image: url("../../../public/image/bg_img.jpg");
    background-repeat: no-repeat;
    background-position: center center;
    transition: background-image 0.5s linear; */
}
.about {
  width: 100%;
  background-color: #f1f1f1;
}
.zxjj {
  width: 100%;
  max-width: 1200px;
  margin: 0px auto;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 20px 0px 100px 0px;
  flex-direction: row;
}
.zxjj_left {
  width: 270px;
  padding-bottom: 36px;
  box-shadow: 0px 2px 10px gray;
  transition: all 0.2s ease-in-out;
  transform: translateY(0px);
  /* transition: width 1.5s, height 1.5s, transform 1.5s;
  -moz-transition: width 1.5s, height 1.5s, -moz-transform 1.5s;
  -webkit-transition: width 1.5s, height 1.5s, -webkit-transform 1.5s;
  -o-transition: width 1.5s, height 1.5s, -o-transform 1.5s; */
}
.zxjj_left:hover {
  cursor: pointer;
}
.zxjj_left_title {
  width: 100%;
  height: 50px;
  background-color: #dee466;
  text-align: center;
  line-height: 50px;
  color: #000000;
  font-size: 18px;
  border-radius: 0px 35px 35px 0px;
}
.zxjj_left_list {
  width: 100%;
  height: 50px;
  background-color: #ebebeb;
  border-top: 1px solid #ffff;
  margin: 5px 0px;
  border-radius: 0px 25px 25px 0px;
}
.zxjj_left_list:hover {
  background-color: #dee466;
  color: #ffff;
}
.zxjj_left_list a {
  display: block;
  width: 80%;
  height: 100%;
  float: right;
  line-height: 50px;
}
.zxjj_left_listacitve {
  width: 270px;
  height: 50px;
  margin: 5px 0px;

  background-color: #dee466;
  color: #fff;
  border-radius: 0px 25px 25px 0px;
  box-shadow: 0px 20px 20px 0px;
  transition: width 1.5s, height 1.5s, transform 1.5s;
  -moz-transition: width 1.5s, height 1.5s, -moz-transform 1.5s;
  -webkit-transition: width 1.5s, height 1.5s, -webkit-transform 1.5s;
  -o-transition: width 1.5s, height 1.5s, -o-transform 1.5s;
}
.zxjj_left_listacitve a {
  display: block;
  width: 80%;
  height: 100%;
  float: right;
  line-height: 50px;
}
.zxjj_right {
  width: 900px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  padding: 0px 0px 0px 20px;
}
.zxjj_right_top {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid #dee466;
}
.zxjj_right_content {
  width: 100%;
}
.zxjj_right_content p {
  text-indent: 2em;
  line-height: 20px;
  text-align: justify;
  text-justify: inter-ideograph;
  color: #333333;
  line-height: 2em;
}
.list-w .list-list,
.nlist_-ul {
  margin-top: 10px;
  list-style: none;
}
.list-w .list-list li .i,
.nlist_-ul li .circle {
  display: inline-block;
  width: 5px;
  height: 5px;
  margin-top: 22px;
  margin: 22px 8px 0;
  vertical-align: top;
  margin-top: 0;
  vertical-align: middle;
  background: #cecece;
  border-radius: 50%;
}
.list-w .list-list li .time,
.nlist_-ul li .time {
  position: absolute;
  right: 0;
  color: #888888;
}
.list-w .list-list li a,
.nlist_-ul li a {
  font-size: 16px;
  display: block;
  margin-left: 22px;
  margin-right: 90px;
}
.el {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: 46px;
}
.clear {
  position: relative;
  border-bottom: 1px dashed gray;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.clear:after {
  content: " ";
  display: block;
  height: 0;
  line-height: 0;
  visibility: hidden;
  position: absolute;
  top: 10px;
  clear: both;
}
/* a {
  font-size: 16px;
  color: #333;
  text-decoration: none;
  font-size: 16px;
  display: block;
  margin-left: 22px;
  margin-right: 90px;
}
body,
div,
ul,
li,
h1,
h2,
h3,
h4,
h5,
h6,
input,
i,
a,
img,
p,
button {
  margin: 0;
  padding: 0;
  font-style: normal;
  vertical-align: top;
  border: none;
  outline: none;
  -webkit-tap-highlight-color: transparent;
} */
dev:hover {
  cursor: pointer;
  border-bottom: 1px solid #000088;
  color: #000088;
}
.list_peo {
  padding-top: 1px;
}
body,
div,
ul,
li,
h1,
h2,
h3,
h4,
h5,
h6,
input,
i,
a,
img,
p,
button {
  margin: 0;
  padding: 0;
  font-style: normal;
  vertical-align: top;
  border: none;
  outline: none;
  -webkit-tap-highlight-color: transparent;
}
ul {
  display: block;
  list-style-type: disc;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
}
.list_peo li {
  padding: 27px 0 35px 60px;
  overflow: hidden;
  border-bottom: 1px dashed #ddd;
}
li {
  list-style-type: none;
}
.list_peo li a {
  font-size: 16px;
  color: #333;
  text-decoration: none;
}
.list_peo li .pic {
  position: relative;
  float: left;
  width: 150px;
  height: 200px;
  border: 1px solid #ededed;
  -moz-box-shadow: 8px 8px 0px #ededed;
  -webkit-box-shadow: 8px 8px 0px #ededed;
  box-shadow: 8px 8px 0px #ededed;
  z-index: 2;
}
/* .list_peo li .pic-bg {
    width: 150px;
    height: 200px;
    position: absolute;
    left: 9px;
    top: 9px;
    background-color: #ededed;
} */
.list_peo li .info {
  width: 100%;
  box-sizing: border-box;
  padding: 5px 0 0 200px;
}
.list_peo .info p {
  color: #333;
  font-size: 16px;
  line-height: 32px;
}
.el-icon-arrow-right:hover {
  cursor: pointer;
}
.el-breadcrumb-item:hover {
  cursor: pointer;
}
/* 列表内容 */
.l_list_wrap {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
}
.l_list_wrap a {
  padding: 10px 10px 10px 10px;
}
.l_list {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 20px 0px 20px 0px;
  border-bottom: 1px solid #eeeeee;
}
.l_list:hover .news_tit {
  color: #d71e06;
  margin-left: 20px;
  cursor: pointer;
}
.news_tit {
  display: block;
  transition: all 0.5s ease 0s;
  overflow: hidden;
  height: 52px;
  line-height: 52px;
  color: #666666;
  font-size: 16px;
  font-weight: bold;
  transition-property: all;
  transition-duration: 1s;
  transition-timing-function: linear;
}
.news_zi {
  height: 50px;
  line-height: 25px;
  overflow: hidden;
  font-size: 14px;
  color: #666666;
}
.news_time {
  height: 46px;
  line-height: 46px;
  overflow: hidden;
  display: block;
  color: #666666;
  font-style: normal;
}

.l_list_img {
  width: 100%;
  height: 100%;
  transition: width 1s, height 1s, transform 1s;
  /* animation: 2s opacity2 0s infinite;
  -webkit-animation: 2s opacity2 0s infinite;-moz-animation: 2s opacity2 0s infinite; */
}
.l_list_img:hover {
  transform: scale(1.2);
}
@media screen and (max-width: 1200px) {
  .zxjj {
    width: 100%;
    max-width: 1200px;
    margin: 0px auto;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 20px 0px 100px 0px;
    flex-direction: column;
  }
  .zxjj_left {
    width: 100%;
    background-color: #ffff;
    transition: width 1.5s, height 1.5s, transform 1.5s;
    -moz-transition: width 1.5s, height 1.5s, -moz-transform 1.5s;
    -webkit-transition: width 1.5s, height 1.5s, -webkit-transform 1.5s;
    -o-transition: width 1.5s, height 1.5s, -o-transform 1.5s;
  }
  .zxjj_left:hover {
    cursor: pointer;
  }
  .zxjj_left_title {
    width: 100%;
    height: 70px;
    background-color: #dee466;
    text-align: center;
    line-height: 70px;
    color: #ffff;
    font-size: 18px;
    border: 1px solid #dee466;
    border-radius: 0px 20px 20px 20px;
  }
  .zxjj_left_list {
    width: 100%;
    height: 50px;
    background-color: #ebebeb;
    border-top: 1px solid #ffff;
  }
  .zxjj_left_list:hover {
    background-color: #dee466;
    color: #ffff;
  }
  .zxjj_left_list a {
    display: block;
    width: 80%;
    height: 100%;
    float: right;
    line-height: 50px;
  }
  .zxjj_left_listacitve {
    width: 100%;
    height: 50px;
    background-color: #dee466;
    color: #fff;
    transition: width 1s, height 1s, transform 1s;
    -moz-transition: width 1s, height 1s, -moz-transform 1s;
    -webkit-transition: width 1s, height 1s, -webkit-transform 1s;
    -o-transition: width 1s, height 1s, -o-transform 1s;
  }
  .zxjj_left_listacitve a {
    display: block;
    width: 80%;
    height: 100%;
    float: right;
    line-height: 50px;
  }
  .zxjj_right {
    width: 95%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0px 0px 0px 20px;
  }
  .el-icon-arrow-right:hover {
    cursor: pointer;
  }
  .el-breadcrumb-item:hover {
    cursor: pointer;
  }
}
</style>
