<template>
  <div class="about">
    about
  </div>
</template>
<script>
export default {
  data(){
    return {
  
    }
  },
  created(){
  
  },
  methods:{
    
 }  
}
</script>
<style scoped>

</style>
