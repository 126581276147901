<template>
  <div class="about">
    <div class="banner"></div>
    <div class="zxjj">
      <div class="zxjj_left">
        <div class="zxjj_left_title">
          <b>{{ titleMessage.name }}</b>
        </div>
        <div
          v-for="(item, index) in titleMessage.childItem"
          :key="index"
          :class="
            index == activeIndex ? 'zxjj_left_listacitve' : 'zxjj_left_list'
          "
          @click="titleActive(item, index)"
        >
          <a>{{ item.name }}</a>
        </div>
      </div>
      <div class="zxjj_right">
        <div class="zxjj_right_top">
          <div>
            <a
              style="
                display: block;
                height: 67px;
                line-height: 67px;
                font-weight: normal;
                color: #003068;
                font-size: 18px;
              "
            >
              {{ acitveItem }}
            </a>
          </div>
          <div>
            <!-- <el-breadcrumb separator-class="el-icon-arrow-right">
              <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
              <el-breadcrumb-item>{{ acitveItem }}</el-breadcrumb-item>
            </el-breadcrumb> -->
          </div>
        </div>
        <div class="zxjj_right_content">
          <div class="l_list_wrap" v-if="activeIndex == 0">
            <div class="l_list" @click="todetail">
              <div style="width: 250px; height: 150px; overflow: hidden">
                <img
                  class="l_list_img"
                  src="../../../public/image/one.jpg"
                  alt=""
                />
              </div>
              <div
                style="width: 540px; padding: 0px 40px 0px 40px; color: #666666"
              >
                <a class="news_tit">更新中…</a>
                <div class="news_zi">
                  <!-- 每段青春都应该经历一次支教，每个灵魂都应该带着爱与微笑。索玛花支教项目，孩子们在山里等你来。山在那里，希望在那里。 -->
                </div>
                <!-- <i class="news_time">2020-06-08</i> -->
              </div>
            </div>
          </div>
          <ul v-else-if="activeIndex == 1" class="nlist_ul">
            <li class="clear">
              <a class="el"
                ><i class="circle left"></i
                ><dev> 浙江大学基础医学院系统医学中心沈颖课题组招聘博士后 </dev>
              </a>
              <span class="time right">2020-06-03</span>
            </li>
            <li class="clear">
              <a class="el"
                ><i class="circle left"></i
                ><dev> 浙江大学基础医学院系统医学中心沈颖课题组招聘博士后 </dev>
              </a>
              <span class="time right">2020-06-03</span>
            </li>
          </ul>
          <ul class="list_peo" v-else-if="activeIndex == 2">
            <li>
              <a>
                <div class="pic">
                  <span class="pic-bg"></span>
                  <img
                    src="../../../public/image/bg_img.jpg"
                    style="width: 150px; height: 200px"
                    alt=""
                  />
                </div>
              </a>
              <div class="info">
                <a href="">
                  <h3>陈伟<span>教授</span></h3>
                </a>
                <p class="over">
                  <strong>个人主页</strong>&nbsp;:&nbsp;<span
                    >膜蛋白动态识别及跨膜激活,工程, 系统生物医学工程</span
                  >
                </p>
                <p class="over">
                  <strong>所在学科系</strong>&nbsp;:&nbsp;<span
                    >细胞生物学系</span
                  >
                </p>
                <p class="over">
                  <strong>所在研究中心</strong>&nbsp;:&nbsp;<span
                    >系统医学研究中心</span
                  >
                </p>
                <p title="">
                  <strong>研究方向</strong>&nbsp;:&nbsp;<span
                    >膜蛋白动态识别及跨膜激活,单细胞及单分子生物医学工程,
                    系统生物医学工程</span
                  >
                </p>
              </div>
            </li>
            <li>
              <a>
                <div class="pic">
                  <span class="pic-bg"></span>
                  <img
                    src="../../../public/image/bg_img.jpg"
                    style="width: 150px; height: 200px"
                    alt=""
                  />
                </div>
              </a>
              <div class="info">
                <a href="">
                  <h3>陈伟<span>教授</span></h3>
                </a>
                <p class="over">
                  <strong>个人主页</strong>&nbsp;:&nbsp;<span
                    >膜蛋白动态识别及跨膜激活,工程, 系统生物医学工程</span
                  >
                </p>
                <p class="over">
                  <strong>所在学科系</strong>&nbsp;:&nbsp;<span
                    >细胞生物学系</span
                  >
                </p>
                <p class="over">
                  <strong>所在研究中心</strong>&nbsp;:&nbsp;<span
                    >系统医学研究中心</span
                  >
                </p>
                <p title="">
                  <strong>研究方向</strong>&nbsp;:&nbsp;<span
                    >膜蛋白动态识别及跨膜激活,单细胞及单分子生物医学工程,
                    系统生物医学工程</span
                  >
                </p>
              </div>
            </li>
            <li>
              <a>
                <div class="pic">
                  <span class="pic-bg"></span>
                  <img
                    src="../../../public/image/bg_img.jpg"
                    style="width: 150px; height: 200px"
                    alt=""
                  />
                </div>
              </a>
              <div class="info">
                <a href="">
                  <h3>陈伟<span>教授</span></h3>
                </a>
                <p class="over">
                  <strong>个人主页</strong>&nbsp;:&nbsp;<span
                    >膜蛋白动态识别及跨膜激活,工程, 系统生物医学工程</span
                  >
                </p>
                <p class="over">
                  <strong>所在学科系</strong>&nbsp;:&nbsp;<span
                    >细胞生物学系</span
                  >
                </p>
                <p class="over">
                  <strong>所在研究中心</strong>&nbsp;:&nbsp;<span
                    >系统医学研究中心</span
                  >
                </p>
                <p title="">
                  <strong>研究方向</strong>&nbsp;:&nbsp;<span
                    >膜蛋白动态识别及跨膜激活,单细胞及单分子生物医学工程,
                    系统生物医学工程</span
                  >
                </p>
              </div>
            </li>
          </ul>
          <div v-else-if="activeIndex == 99">
            <b
              style="
                display: block;
                width: 100%;
                text-align: center;
                font-size: 18px;
                line-height: 100px;
              "
              >多学科联动共促血栓防治新格局策略学术会 项目总体方案</b
            >
            <small
              style="
                display: block;
                width: 100%;
                text-align: center;
                border-bottom: 1px solid gray;
                line-height: 50px;
              "
              >时间：2020-07-03</small
            >
            <div
              style="
                width: 100%;
                margin-top: 50px;
                font-family: 仿宋;
                line-height: 150%;
                font-size: 21px;
              "
            >
              多学科联动共促血栓防治新格局策略学术会 项目总体方案 一、项目背景
              静脉血栓栓塞症（VTE）是一个全球性的疾病，包括深静脉血栓栓塞症（DVT）+肺动脉栓塞（PE）。发病隐匿，来势凶猛，在西方国家，每16秒就有1个人发生VTE，每37秒就有1人因VTE死亡，每年死亡人数超过843,
              000。VTE是继缺血性心脏病和卒中之后位列第三的最常见的心血管疾病。然而临床上漏误诊率极高，治疗上也多有不规范现象，涉及科室广，不同学科对VTE的诊治水平差异较大，且尚未建立国人规范的诊疗技术体系。
              为了推进对VTE的防治研究，建立多学科一体的防治策略是住院患者预防VTE发生的必要途径和保障。由北京新兴卫生产业发展基金会发起“多学科联动共促血栓防治新格局策略学术会”项目，通过本次活动联合多学科，开展VTE的防治战略工作；有效的提高医护人员对于抗凝治疗的认识，进一步让医务人员共同分享各家医院临床使用抗凝药物经验；切实做好静脉血栓的风险评估、预防干预和早期治疗工作，进而全面提升VTE防治水平，帮助患者减轻由VTE而带来的严重后果。为提高北京医疗服务质量和人民健康水平做出积极贡献，为广大患者造福。
              二、项目名称：多学科联动共促血栓防治新格局策略学术会
              项目周期：2020年9月—2021年3月 项目场次：112场 项目预算：205万
              项目形式：线上会议直播 线上会议平台：医者佳音 三、项目内容
              1、肿瘤患者VTE临床实践对预防和治疗的思考；
              2、妇科肿瘤VTE防治管理和临床诊疗体会；
              3、VTE防控体系建设及抗凝策略； 4、老年下肢骨折患者DVT的防治；
              5、骨科手术患者VTE筛查与诊治实践； 6、VTE抗凝治疗与出血管理；
              7、突发疫情到新常态-VTE临床管理实践；
              8、急性冠状动脉综合征抗栓治疗合并出血防治。 四、项目实施
              主办单位：北京新兴卫生产业发展基金会
              资助单位：拜耳医药保健有限公司
              承办单位：北京颐佳达文健康管理（北京）有限公司 五、项目管理
              北京新兴卫生产业发展基金会成立“多学科联动共促血栓防治新格局策略学术会”项目组委会，负责项目的策划和申报，由项目组委会经基金会审批立项后负责项目的组织管理和资金募集。
              本项目将委托第三方执行团队进行项目管理，同时将在项目组委会的指导和监督下，开展项目的前期沟通，包括专家委员会的协调组织、会前讲者沟通，会议内容校对、会后内容审核等工作。项目中期运营工作，在医者佳音平台上召开网络会包含内容预制、会议通知、会议反馈及在线客服支持，由平台负责按照乙方的指示和要求邀请不少于40位相关领域及科室临床医生线上观看，并提供会议执行情况数据报告（含会议时长、讲题和参会人员情况等信息）；项目后期将及时提供相关设计、视频、会议截图等总结备案资料，以备存档查验。主办方将对第三方执行团队的行为和后果负责。
              讲者/主席会前身份验证流程及参会流程：讲者/主席医生需登录网络平台“医者佳音”进行用户注册，审核完成后，经主办方邀请作为讲者/主席在相关在线会议上讲课。
              参会人员参会流程：参会医生可登录网络平台“医者佳音”，如实填写个人信息进行用户注册，注册审核通过后，可以报名参加网络会议。
              六、项目经费管理
              具体项目的经费支出由该项目项目组拟定项目执行方案、经费预算和决算，按基金会的财务管理和审批流程审批后支付。
              七、项目评估 由承办单位负责项目的执行中的所有资料的收集
              、总结项目执行情况，并上报相关活动照片报组委会，由组委会对会议情况进行总结评估，以及通过此项活动对妇科专业医师的培训起到实际作用。
              北京新兴卫生产业发展基金会
              多学科联动共促血栓防治新格局策略学术会组委会 2020年9月
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import eventbus from "@/api/eventbus";
export default {
  data() {
    return {
      activeIndex: 1,
      acitveItem:JSON.parse(window.sessionStorage.getItem("titleMessage")).childItem[0].name || "",
      // acitveItem:"",
      titleMessage:
        JSON.parse(window.sessionStorage.getItem("titleMessage")) || {},
      xiangqing: false,
    };
  },
  mounted() {
    //   eventbus.$on('headerTitle',(message)=>{
    //   console.log('message',message)
    //   window.sessionStorage.setItem('titleMessage',JSON.stringify(message))
    // })
    this.activeIndex = this.$route.query.index;
    eventbus.$on("childindex", (index) => {
      this.activeIndex = index;
      this.acitveItem = this.titleMessage.childItem[index].name;
    });
  },
  methods: {
    titleActive(item, index) {
      this.acitveItem = item.name;
      this.activeIndex = index;
    },
    todetail() {
      // this.xiangqing = true;
      // this.activeIndex = 99;
    },
  },
};
</script>
<style scoped>
.about {
  width: 100%;
}
/*顶部 banner */
.banner {
  width: 100%;
  background-image: url("../../../public/image/banner.jpg");
  background-repeat: no-repeat;
  background-position: center center;
  height: 450px;

  /* animation: 2s opacity2 0s infinite;
    -webkit-animation: 2s opacity2 0s infinite;-moz-animation: 2s opacity2 0s infinite;
    position: relative;
    height: 315px;
    background-image: url("../../../public/image/bg_img.jpg");
    background-repeat: no-repeat;
    background-position: center center;
    transition: background-image 0.5s linear; */
}
.zxjj {
  width: 100%;
  max-width: 1200px;
  margin: 0px auto;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 20px 0px 100px 0px;
  flex-direction: row;
}
.zxjj_left {
  width: 270px;
  padding-bottom: 36px;
  box-shadow: 0px 2px 10px gray;
  transition: all 0.2s ease-in-out;
  transform: translateY(0px);
  /* transition: width 1.5s, height 1.5s, transform 1.5s;
  -moz-transition: width 1.5s, height 1.5s, -moz-transform 1.5s;
  -webkit-transition: width 1.5s, height 1.5s, -webkit-transform 1.5s;
  -o-transition: width 1.5s, height 1.5s, -o-transform 1.5s; */
}
.zxjj_left:hover {
  cursor: pointer;
}
.zxjj_left_title {
  width: 100%;
  height: 50px;
  background-color: #dee466;
  text-align: center;
  line-height: 50px;
  color: #000000;
  font-size: 18px;
  border-radius: 0px 35px 35px 0px;
}
.zxjj_left_list {
  width: 100%;
  height: 50px;
  background-color: #ebebeb;
  border-top: 1px solid #ffff;
  margin: 5px 0px;
  border-radius: 0px 25px 25px 0px;
}
.zxjj_left_list:hover {
  background-color: #dee466;
  color: #ffff;
}
.zxjj_left_list a {
  display: block;
  width: 80%;
  height: 100%;
  float: right;
  line-height: 50px;
}
.zxjj_left_listacitve {
  width: 270px;
  height: 50px;
  margin: 5px 0px;

  background-color: #dee466;
  color: #fff;
  border-radius: 0px 25px 25px 0px;
  box-shadow: 0px 20px 20px 0px;
  transition: width 1.5s, height 1.5s, transform 1.5s;
  -moz-transition: width 1.5s, height 1.5s, -moz-transform 1.5s;
  -webkit-transition: width 1.5s, height 1.5s, -webkit-transform 1.5s;
  -o-transition: width 1.5s, height 1.5s, -o-transform 1.5s;
}
.zxjj_left_listacitve a {
  display: block;
  width: 80%;
  height: 100%;
  float: right;
  line-height: 50px;
}
.zxjj_right {
  width: 900px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  padding: 0px 0px 0px 20px;
}
.zxjj_right_top {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid #dee466;
}
.zxjj_right_content {
  width: 100%;
}
.zxjj_right_content p {
  text-indent: 2em;
  line-height: 20px;
  text-align: justify;
  text-justify: inter-ideograph;
  color: #333333;
  line-height: 2em;
}
.list-w .list-list,
.nlist_-ul {
  margin-top: 10px;
  list-style: none;
}
.list-w .list-list li .i,
.nlist_-ul li .circle {
  display: inline-block;
  width: 5px;
  height: 5px;
  margin-top: 22px;
  margin: 22px 8px 0;
  vertical-align: top;
  margin-top: 0;
  vertical-align: middle;
  background: #cecece;
  border-radius: 50%;
}
.list-w .list-list li .time,
.nlist_-ul li .time {
  position: absolute;
  right: 0;
  color: #888888;
}
.list-w .list-list li a,
.nlist_-ul li a {
  font-size: 16px;
  display: block;
  margin-left: 22px;
  margin-right: 90px;
}
.el {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: 46px;
}
.clear {
  position: relative;
  border-bottom: 1px dashed gray;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.clear:after {
  content: " ";
  display: block;
  height: 0;
  line-height: 0;
  visibility: hidden;
  position: absolute;
  top: 10px;
  clear: both;
}
a {
  font-size: 16px;
  color: #333;
  text-decoration: none;
  font-size: 16px;
  display: block;
  margin-left: 22px;
  margin-right: 90px;
}
body,
div,
ul,
li,
h1,
h2,
h3,
h4,
h5,
h6,
input,
i,
a,
img,
p,
button {
  margin: 0;
  padding: 0;
  font-style: normal;
  vertical-align: top;
  border: none;
  outline: none;
  -webkit-tap-highlight-color: transparent;
}
dev:hover {
  cursor: pointer;
  border-bottom: 1px solid #000088;
  color: #000088;
}
.list_peo {
  padding-top: 1px;
}
body,
div,
ul,
li,
h1,
h2,
h3,
h4,
h5,
h6,
input,
i,
a,
img,
p,
button {
  margin: 0;
  padding: 0;
  font-style: normal;
  vertical-align: top;
  border: none;
  outline: none;
  -webkit-tap-highlight-color: transparent;
}
ul {
  display: block;
  list-style-type: disc;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
}
.list_peo li {
  padding: 27px 0 35px 60px;
  overflow: hidden;
  border-bottom: 1px dashed #ddd;
}
li {
  list-style-type: none;
}
.list_peo li a {
  font-size: 16px;
  color: #333;
  text-decoration: none;
}
.list_peo li .pic {
  position: relative;
  float: left;
  width: 150px;
  height: 200px;
  border: 1px solid #ededed;
  -moz-box-shadow: 8px 8px 0px #ededed;
  -webkit-box-shadow: 8px 8px 0px #ededed;
  box-shadow: 8px 8px 0px #ededed;
  z-index: 2;
}
/* .list_peo li .pic-bg {
    width: 150px;
    height: 200px;
    position: absolute;
    left: 9px;
    top: 9px;
    background-color: #ededed;
} */
.list_peo li .info {
  width: 100%;
  box-sizing: border-box;
  padding: 5px 0 0 200px;
}
.list_peo .info p {
  color: #333;
  font-size: 16px;
  line-height: 32px;
}
.el-icon-arrow-right:hover {
  cursor: pointer;
}
.el-breadcrumb-item:hover {
  cursor: pointer;
}
/* 列表内容 */
.l_list_wrap {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
}
.l_list {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 20px 0px 20px 0px;
  border-bottom: 1px solid #eeeeee;
}
.l_list:hover .news_tit {
  color: #d71e06;
  margin-left: 20px;
  cursor: pointer;
}
.news_tit {
  display: block;
  transition: all 0.5s ease 0s;
  overflow: hidden;
  height: 52px;
  line-height: 52px;
  color: #666666;
  font-size: 16px;
  font-weight: bold;
  transition-property: all;
  transition-duration: 1s;
  transition-timing-function: linear;
}
.news_zi {
  height: 50px;
  line-height: 25px;
  overflow: hidden;
  font-size: 14px;
  color: #666666;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.news_time {
  height: 46px;
  line-height: 46px;
  overflow: hidden;
  display: block;
  color: #666666;
  font-style: normal;
}

.l_list_img {
  width: 100%;
  height: 100%;
  transition: width 1s, height 1s, transform 1s;
  /* animation: 2s opacity2 0s infinite;
  -webkit-animation: 2s opacity2 0s infinite;-moz-animation: 2s opacity2 0s infinite; */
}
.l_list_img:hover {
  transform: scale(1.2);
}
@media screen and (max-width: 1200px) {
  .zxjj {
    width: 100%;
    max-width: 1200px;
    margin: 0px auto;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 20px 0px 100px 0px;
    flex-direction: column;
  }
  .zxjj_left {
    width: 100%;
    transition: width 1.5s, height 1.5s, transform 1.5s;
    -moz-transition: width 1.5s, height 1.5s, -moz-transform 1.5s;
    -webkit-transition: width 1.5s, height 1.5s, -webkit-transform 1.5s;
    -o-transition: width 1.5s, height 1.5s, -o-transform 1.5s;
  }
  .zxjj_left:hover {
    cursor: pointer;
  }
  .zxjj_left_title {
    width: 100%;
    height: 70px;
    background-color: #1f518c;
    text-align: center;
    line-height: 70px;
    color: #ffff;
    font-size: 18px;
  }
  .zxjj_left_list {
    width: 100%;
    height: 50px;
    background-color: #ebebeb;
    border-top: 1px solid #ffff;
  }
  .zxjj_left_list:hover {
    background-color: #626d84;
    color: #ffff;
  }
  .zxjj_left_list a {
    display: block;
    width: 80%;
    height: 100%;
    float: right;
    line-height: 50px;
  }
  .zxjj_left_listacitve {
    width: 100%;
    height: 50px;
    background-color: #626d84;
    color: #fff;
    transition: width 1s, height 1s, transform 1s;
    -moz-transition: width 1s, height 1s, -moz-transform 1s;
    -webkit-transition: width 1s, height 1s, -webkit-transform 1s;
    -o-transition: width 1s, height 1s, -o-transform 1s;
  }
  .zxjj_left_listacitve a {
    display: block;
    width: 80%;
    height: 100%;
    float: right;
    line-height: 50px;
  }
  .zxjj_right {
    width: 95%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0px 0px 0px 20px;
  }
  .el-icon-arrow-right:hover {
    cursor: pointer;
  }
  .el-breadcrumb-item:hover {
    cursor: pointer;
  }
}
</style>
